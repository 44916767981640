export default (getView) => (nextStepPath) => ({
  ...getView(nextStepPath),

  getButtonState() {
    const { privacyAgreement } = this.formValues
    const notAgreed = privacyAgreement == "17-create-plan.pa_answer2"
    const agreementErrorMessage = "(you must agree to the terms to proceed)"

    this.errors = {
      privacyAgreement: notAgreed ? agreementErrorMessage : "",
      email: this.errors.email,
    }

    if (notAgreed) {
      this.errors = {
        privacyAgreement: notAgreed ? agreementErrorMessage : "",
      }
    }
  },
})
