import Alpine from "alpinejs"
import { load, store } from "../localStorage"
import validate from "../validations"
import weightView from "./03-weight"
import ageView from "./04-age"
import heightView from "./05-height"
import nameView from "./07-name"
import summaryEditView from "./09-summary-edit"
import targetWeightView from "./10-ideal-weight"
import planApetiteView from "./13-plan-apetite"
import planLikeView from "./14-plan-like"
import planFirstMealView from "./15-plan-first-meal"
import planFrequencyView from "./16-plan-frequency"
import createPlanView from "./17-create-plan"
import thankYouView from "./thank-you"
import primer from "./primer"

const LOCAL_STORAGE_KEY = "view"
const GLOBAL_STORAGE_KEY = "data"
const EMAIL_VALIDATION_ERROR = "email: Invalid email"
const EMAIL_INVALID_DOMAIN_CODE = "invalid_domain"
const EMAIL_VALIDATION_CODE = "email_verification_failed"

const view = (nextStepPath) => ({
  showSpinner: false,
  formValues: {},
  errors: {},
  showFetchError: false,
  isInvalid: false,

  init() {
    this.store = Alpine.store("data")
    const data = load(LOCAL_STORAGE_KEY)

    if (this.onInit) this.onInit(data)

    if (data && data.errors && data.path === window.location.pathname) {
      this.formValues = data.formValues
      this.errors = data.errors
      this.showSpinner = false
    }

    this.getButtonState()

    window.addEventListener("pageshow", (event) => {
      if (event.persisted) {
        this.showSpinner = false
      }
    })
  },

  checkEmailValidation() {
    const errResponseData = this.store.values.errorResponseData
    if (errResponseData) {
      if (
        errResponseData.error === EMAIL_VALIDATION_ERROR ||
        errResponseData.code === EMAIL_INVALID_DOMAIN_CODE ||
        errResponseData.code === EMAIL_VALIDATION_CODE
      ) {
        return true
      }
    }

    return false
  },

  getButtonState() {
    Object.values(this.formValues).every((v) => !!v || v === 0)
  },

  populateFormValuesFromStorage(values) {
    const globalData = load(GLOBAL_STORAGE_KEY)
    values.map((value) => {
      const globalMatch = globalData.values[value]

      if (!globalMatch) return

      this.formValues[value] = globalMatch
    })

    this.getButtonState()
  },

  resetErrors() {
    this.errors = {}
  },

  justUpdate(field, value) {
    this.formValues[field] = value
    this.$refs[`${field}.${value}`].checked = true
  },

  update(field, value) {
    this.formValues[field] = value
    this.submit()
  },

  additionalStoreData() {
    return {}
  },

  processFormValues() {
    return this.formValues
  },

  getNextStepPath() {
    return nextStepPath
  },

  redirectToNextStep() {
    window.location = this.getNextStepPath()
  },

  storeData(data) {
    store(LOCAL_STORAGE_KEY, {
      path: window.location.pathname,
      ...data,
      ...this.additionalStoreData(),
    })
  },

  handleInvalidInputEvent(e) {
    if (this.isInvalid) {
      return
    }

    this.isInvalid = true

    setTimeout(() => {
      this.isInvalid = false
    }, 1000)

    if (e && e.target) {
      e.target.scrollIntoView({ block: "center", inline: "nearest" })
    }
  },

  scrollToPrivacyAgreementError() {
    document
      .querySelector('[x-ref="privacyAgreement.17-create-plan.pa_answer2"]')
      .scrollIntoView({ block: "center", inline: "nearest" })
  },

  async submit(push = false) {
    const formValues = this.processFormValues()

    const { hasErrors, errors } = validate(formValues)

    if (hasErrors) {
      this.storeData({ errors, formValues: this.formValues })

      if (this.formValues.privacyAgreement == "17-create-plan.pa_answer2") {
        this.scrollToPrivacyAgreementError()
      } else {
        window.location.reload()
      }
    } else {
      this.showSpinner = true

      this.storeData(null)
      this.store.values = { ...this.store.values, ...formValues }

      if (!push) {
        window.location = this.getNextStepPath()
      } else if (await this.store.push()) {
        this.errors = {}
        window.location = this.getNextStepPath()
      } else {
        const isEmailError = this.checkEmailValidation()
        if (isEmailError) {
          this.store.values.email = ""
          this.errors.email = true
        } else {
          this.showFetchError = true
        }
        this.showSpinner = false
      }
    }
  },
})

export default [
  { name: "view", data: view },
  { name: "weightView", data: weightView(view) },
  { name: "ageView", data: ageView(view) },
  { name: "heightView", data: heightView(view) },
  { name: "nameView", data: nameView(view) },
  { name: "summaryEditView", data: summaryEditView(view) },
  { name: "targetWeightView", data: targetWeightView(view) },
  { name: "planApetiteView", data: planApetiteView(view) },
  { name: "planLikeView", data: planLikeView(view) },
  { name: "planFirstMealView", data: planFirstMealView(view) },
  { name: "planFrequencyView", data: planFrequencyView(view) },
  { name: "createPlanView", data: createPlanView(view) },
  { name: "thankYouView", data: thankYouView(view) },
  { name: "primer", data: primer(view) },
]
