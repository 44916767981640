import { load } from "../localStorage"
const { Primer } = window

const PRIMER_LOCALES = [
  "de",
  "el",
  "en",
  "es",
  "fr",
  "pl",
  "pt",
  "bg",
  "hu",
  "ro",
  "ru",
  "sk",
  "nl",
]

export default (getView) => (urls) => ({
  ...getView(urls),

  // Displays error message with reload button that refresh the page
  displayErrorMessage() {
    const offlineBlock = window.document.getElementById("offline-msg")
    offlineBlock.style.display = "block"
  },

  // Checks for the compability with locale
  isPrimerLocale(locale) {
    return PRIMER_LOCALES.includes(locale || "en")
  },

  // Primer Checkout options - config, styles, buttons, texts etc.
  primerCheckoutOption(store, primerSession) {
    const paymentHost = `${pipelineConfig.productApiUrl}`

    const options = {
      container: "#checkout-container",
      locale: pipelineConfig.language || "en",
      vault: {
        visible: false,
      },
      successScreen: false,
      errorMessage: false,
      async onCheckoutComplete({ payment }) {
        window.location = `${paymentHost}/pay/primer/post-payment/${primerSession.invoice.id}`
      },
      async onCheckoutFail(error, { payment }) {
        await store.markPaymentAsFailed()

        window.location = `${paymentHost}/pay/primer/post-payment/${primerSession.invoice.id}`
      },
      submitButton: {
        useBuiltInButton: this.isPrimerLocale(pipelineConfig.language),
        onVisible: (isVisible) => {
          const submitButton = window.document.getElementById("submit-button")
          if (!submitButton) return

          if (isVisible) {
            submitButton.style.display = "flex"
          } else {
            submitButton.style.display = "none"
          }
        },
        onDisable: (isDisabled) => {
          const submitButton = window.document.getElementById("submit-button")
          if (!submitButton) return

          if (isDisabled) {
            submitButton.disabled = true
          } else {
            submitButton.disabled = false
          }
        },
        onLoading: (isLoading) => {
          const submitButton = window.document.getElementById("submit-button")
          if (!submitButton) return

          if (isLoading) {
            submitButton.getElementsByClassName("button-loader")[0].style.display = "block"
          } else {
            submitButton.getElementsByClassName("button-loader")[0].style.display = "none"
          }
        },
      },
      card: {
        cardholderName: {
          placeholder: "",
        },
      },
      paypal: {
        buttonColor: "gold",
        buttonShape: "rect",
        paymentFlow: "PREFER_VAULT",
      },
      googlePay: {
        captureBillingAddress: true,
      },
      applePay: {
        captureBillingAddress: true,
      },
      style: {
        formSpacings: {
          betweenLabelAndInput: "12px",
          betweenInputs: "16px",
        },
        inputLabel: {
          fontFamily: "Acumin, Arial, sans-serif",
          fontSize: "12px",
          lineHeight: "normal",
          fontWeight: "normal",
          color: "#404040",
        },
        input: {
          base: {
            background: "#fff",
            color: "#35383C",
          },
        },
        inputErrorText: {
          fontSize: "14px",
          fontWeight: "400",
        },
        submitButton: {
          base: {
            fontFamily: "AcuminSemi, Arial, sans-serif",
            color: "#fff",
            background: "linear-gradient(260deg, #60e474, #269153)",
            fontSize: "20px",
            fontWeight: "600",
            borderRadius: "6px",
          },
        },
      },
      redirect: {
        returnUrl: `${paymentHost}/pay/primer/post-payment/${primerSession.invoice.id}`,
        forceRedirect: true,
      },
    }

    return options
  },

  // Creates Primer session from our BE api-diet
  async createSession(pipelineData) {
    if (pipelineData?.preloaded && pipelineData?.checkout) {
      return pipelineData?.checkout
    }

    try {
      return await this.store.createPrimerSession(pipelineData.activeOffer, urls.failUrl)
    } catch (e) {
      console.error(e)
      this.displayErrorMessage()
      return
    }
  },

  // Loads a Primer Checkout
  async loadCheckout(primerSession, store) {
    try {
      const options = this.primerCheckoutOption(store, primerSession)
      const primerCheckout = await Primer.showUniversalCheckout(
        primerSession.invoice.session_identifier,
        options
      )

      return primerCheckout
    } catch (e) {
      console.log(e)
      this.displayErrorMessage()
      return
    }
  },

  // Patch the primer translations with ours
  patchTranslations(checkout) {
    const applyCustomLabel = (field) => {
      // Remove default primer label
      const currentField = window.document.getElementById(field.id)
      currentField.getElementsByClassName("PrimerCheckout__label")[0].style.display = "none"

      // Create ours
      const newField = window.document.createElement("div")
      newField.dataset.testid = "CreditCardForm.inputLabel"
      newField.classList.add("primer-input-label")
      newField.classList.add("PrimerCheckout__label")
      newField.innerHTML = field.translation

      // Display our label
      currentField.insertBefore(newField, currentField.firstChild)
    }

    const applyCustomErrorMessage = (field) => {
      const currentField = document.getElementById(field.id)
      const targetNode = currentField.getElementsByTagName("div")[0]

      // Mutation config
      const config = { childList: true }

      // Callback function executes on dom changes
      const callback = (mutationList) => {
        for (const mutation of mutationList) {
          if (mutation.type === "childList") {
            // Apply our custom translation
            if (targetNode.innerHTML !== field.translation && targetNode.innerHTML !== "") {
              targetNode.innerHTML = field.translation
            }
          }
        }
      }
      // Create/Observe the node mutations
      const observer = new MutationObserver(callback)
      observer.observe(targetNode, config)
    }

    const applyCustomSubmitButton = (checkout) => {
      // Hide primer original button
      const primerSubmitButton = window.document.getElementById(
        "primer-checkout-submit-button-container"
      )
      primerSubmitButton.style.display = "none"

      const primerWindow = window.document.getElementById("primer-checkout")
      const buttonElement = window.document.createElement("button")
      const laoderElement = window.document.createElement("div")
      const spanElement = window.document.createElement("span")

      // Apply button params
      buttonElement.id = "submit-button"
      buttonElement.className = "submit-button"
      buttonElement.name = "submit"
      buttonElement.type = "button"
      buttonElement.addEventListener(
        "click",
        () => {
          checkout.submit()
        },
        false
      )
      // Apply loader params
      laoderElement.className = "button-loader"
      // Apply span params
      spanElement.className = "button-text mr-2"
      spanElement.innerHTML = primerTranslations.button

      buttonElement.appendChild(spanElement)
      buttonElement.appendChild(laoderElement)
      primerWindow.appendChild(buttonElement)
    }

    const primerFields = [
      {
        labelField: {
          id: "primer-checkout-card-number-field",
          translation: primerTranslations.cardnumber_label,
        },
        errorField: {
          id: "primer-checkout-card-number",
          translation: primerTranslations.error.empty_field,
        },
      },
      {
        labelField: {
          id: "primer-checkout-card-expirydate-field",
          translation: primerTranslations.expire_label,
        },
        errorField: {
          id: "primer-checkout-card-expirydate-error",
          translation: primerTranslations.error.empty_field,
        },
      },
      {
        labelField: {
          id: "primer-checkout-card-cvv-field",
          translation: primerTranslations.cvc_label,
        },
        errorField: {
          id: "primer-checkout-card-cvv-error",
          translation: primerTranslations.error.empty_field,
        },
      },
      {
        labelField: {
          id: "primer-checkout-cardholder-name-field",
          translation: primerTranslations.username_label,
        },
        errorField: {
          id: "primer-checkout-cardholder-name-error",
          translation: primerTranslations.error.empty_field,
        },
      },
    ]

    primerFields.forEach((field) => {
      applyCustomLabel(field.labelField)
      applyCustomErrorMessage(field.errorField)
    })
    applyCustomSubmitButton(checkout)
  },

  onInit() {
    this.store = Alpine.store("data")
    const pipelineData = this.store.values
    window.addEventListener("load", async () => {
      const primerSession = await this.createSession(pipelineData)
      if (!primerSession) {
        this.displayErrorMessage()
        return
      }

      // Display checkout
      const checkout = await this.loadCheckout(primerSession, this.store)

      // Update pipeline
      await this.store.initiateCheckout(
        primerSession.invoice.identifier,
        primerSession,
        urls.paymentSuccessUrl,
        urls.paymentFailUrl
      )

      // If primer doesn't support locale add from translations
      if (!this.isPrimerLocale(pipelineConfig.language)) {
        this.patchTranslations(checkout)
      }
    })
  },
})
