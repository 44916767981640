function Timer(store) {
  let countdownTime = 900 // 15 minutes in seconds
  // Add timer
  setInterval(updateCountdown, 1000)

  // Find elements
  const hh = document.getElementById("hh")
  const mm = document.getElementById("mm")
  const ss = document.getElementById("ss")

  function updateCountdown() {
    const hours = Math.floor(countdownTime / 3600)
    const minutes = Math.floor((countdownTime % 3600) / 60)
    const seconds = countdownTime % 60

    hh.innerHTML = `${hours.toString().padStart(2, "0")}`
    mm.innerHTML = `${minutes.toString().padStart(2, "0")}`
    ss.innerHTML = `${seconds.toString().padStart(2, "0")}`

    countdownTime--

    // Remove timer
    if (countdownTime < 0) {
      countdownTime = 900
    }
  }

  function getFormattedPeriodPrice() {
    return (
      store.data.values.activeOffer.customData.symbol +
      store.data.values.activeOffer.customData.period_price
    )
  }

  return {
    getFormattedPeriodPrice,
  }
}

export default Timer
