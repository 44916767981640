import Testimonials from "./Testimonials"
import OfferSelect from "./OfferSelect"
import Timer from "./Timer"
import Checkout from "./Checkout"

function Utils() {
  return {
    Testimonials,
    OfferSelect,
    Timer,
    Checkout,
  }
}

export default Utils
