function Checkout(store, paymentMethods, successful_payment_path, failed_payment_path) {
  const paymodes = paymentMethods.split(",")
  const offer = store.data.values.activeOffer
  const offerData = offer.customData
  const selectedPaymentMethod = paymodes.find((paymode) => paymode === "primer") || paymodes[0]

  function init() {
    let searchParams = new URLSearchParams(window.location.search)
    if ((paymode = searchParams.get("error"))) {
      this.error = paymode
      this.activePaymentMethod = paymode
    }
  }

  function selectPaymentMethod(paymentMethod) {
    if (paymentMethod !== this.activePaymentMethod) {
      this.error = null
    }

    this.activePaymentMethod = paymentMethod
  }

  function getFormattedPriceWithoutDiscount() {
    return offerData.symbol + offerData.total_price_without_discount
  }

  function getFormattedDiscount() {
    return offerData.symbol + offerData.total_price_discount
  }

  function getFormattedTotal() {
    return offerData.symbol + offerData.total_price
  }

  return {
    init,
    selectPaymentMethod,
    getFormattedPriceWithoutDiscount,
    getFormattedDiscount,
    getFormattedTotal,
    activePaymentMethod: selectedPaymentMethod,
    offer: offer,
    offerData: offerData,
    error: null,
  }
}

export default Checkout
