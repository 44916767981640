function Testimonials() {
  const userTestimonials = ["first", "second", "third"]

  function onClickBackwards() {
    const nextTestimonial = userTestimonials[userTestimonials.indexOf(this.activeTestimonial) - 1]
    if (!nextTestimonial) {
      activeTestimonial = "first"
      return
    }

    this.activeTestimonial = nextTestimonial
  }

  function onClickForwards() {
    const nextTestimonial = userTestimonials[userTestimonials.indexOf(this.activeTestimonial) + 1]
    if (!nextTestimonial) {
      activeTestimonial = "third"
      return
    }

    this.activeTestimonial = nextTestimonial
  }

  return { activeTestimonial: "first", onClickBackwards, onClickForwards }
}

export default Testimonials
