const MIN_WEIGHT = 30
const MAX_WEIGHT = 189

const MIN_HEIGHT = 100
const MAX_HEIGHT = 250

const MIN_AGE = 18
const MAX_AGE = 100

const MIN_WEIGHT_LBS = 66
const MAX_WEIGHT_LBS = 415

const MIN_HEIGHT_FT = 3
const MAX_HEIGHT_FT = 8

const MIN_HEIGHT_IN = 0
const MAX_HEIGHT_IN = 12

isString = (value) => typeof value === "string"

const validators = {
  measurementSystem: (value) => ["imperial", "metric"].includes(value),
  gender: (value) => ["female", "male"].includes(value),
  goal: (value) => ["Lose weight", "Develop healthy habits"].includes(value),
  email: (value) => isString(value) && value.match(/^\S+@\S+\.\S+$/),
  age: (value) => value !== null && Number(value) >= MIN_AGE && Number(value) <= MAX_AGE,
  targetWeightKg: (value) => !!Number(value),
  targetWeightLbs: (value) => !!Number(value),
  firstName: (value) => isString(value) && value.length > 0 && !!value.trim(),
  weightKg: (value, { isMetric }) =>
    (value !== null && Number(value) >= MIN_WEIGHT && Number(value) <= MAX_WEIGHT) || !isMetric,
  weightLbs: (value, { isMetric }) =>
    (value !== null && Number(value) >= MIN_WEIGHT_LBS && Number(value) <= MAX_WEIGHT_LBS) ||
    isMetric,
  heightCm: (value, { isMetric }) =>
    (value !== null && Number(value) >= MIN_HEIGHT && Number(value) <= MAX_HEIGHT) || !isMetric,
  heightFt: (value, { isMetric }) =>
    (value !== null && Number(value) >= MIN_HEIGHT_FT && Number(value) <= MAX_HEIGHT_FT) ||
    isMetric,
  heightIn: (value, { isMetric }) =>
    (value !== null && Number(value) >= MIN_HEIGHT_IN && Number(value) <= MAX_HEIGHT_IN) ||
    isMetric,
  privacyAgreement: (value) => value !== "17-create-plan.pa_answer2",
}

const ERROR_MESSAGES = new Proxy(
  {
    age: "errors.age",
    email: "errors.email",
    heightCm: "errors.heightCm",
    heightFt: "errors.heightFt",
    heightIn: "errors.heightIn",
    weightKg: "errors.weightKg",
    firstName: "errors.firstName",
    weightLbs: "errors.weightLbs",
    privacyAgreement: "errors.privacyAgreement",
  },
  {
    get: (target, name) => (target.hasOwnProperty(name) ? target[name] : "invalid"),
  }
)

const validate = (data) => {
  const errors = {}
  let hasErrors = false

  Object.entries(data).forEach(([field, value]) => {
    const validator = validators[field]

    if (validator && !validator(value, data)) {
      hasErrors = true
      errors[field] = ERROR_MESSAGES[field]
    }
  })

  return { hasErrors, errors }
}

export default validate
