function OfferSelect(store, nextStepPath) {
  const storedOffer = store.data.values.activeOffer

  const pipelineOffers = pipelineConfig.offers
  const orderedOffers = pipelineOffers.sort((a, b) => a.customData.sequence - b.customData.sequence)
  const selectedOffer =
    storedOffer || orderedOffers.find((offer) => offer.customData.default_select === "true")
  let showSpinner = false

  function init() {
    window.addEventListener("pageshow", (event) => {
      if (event.persisted) {
        this.showSpinner = false
      }
    })
  }

  async function submit() {
    this.showSpinner = true

    try {
      await store.data.createPrimerSession(this.activeOffer, nextStepPath)

      window.location = nextStepPath
    } catch (error) {
      this.showSpinner = false
    }
  }

  function selectOffer(offer) {
    this.activeOffer = offer
    store.data.values.activeOffer = offer
  }

  return {
    activeOffer: selectedOffer,
    pipelineOffers: orderedOffers,
    showSpinner: showSpinner,
    init,
    submit,
    selectOffer,
  }
}

export default OfferSelect
